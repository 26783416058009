import React, { useRef, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Menu,
  MenuItem,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MenuIcon from '@mui/icons-material/Menu';
import './App.css'; // Import your existing CSS
import myImage from '../src/woman-8656655_1280.jpg';

// Custom Theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1a73e8', // Blue for primary buttons and accents
    },
    secondary: {
      main: '#f50057', // Pink for secondary actions
    },
    background: {
      default: '#f4f6f8', // Light gray background for sections
      paper: '#ffffff', // White for cards and containers
    },
    text: {
      primary: '#333333', // Darker text for readability
      secondary: '#ffffff', // White for text on darker backgrounds
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h3: {
      fontWeight: 700, // Bold headings
    },
    h4: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 500,
    },
  },
});

function App() {
  // Create refs for sections
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);
  const accessRef = useRef(null);
  const surveillanceRef = useRef(null);

  // State for menu visibility
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // Scroll to section function
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setMenuAnchorEl(null); // Close menu after selecting
  };

  // Handler for phone call
  const handleCall = () => {
    window.location.href = 'tel:2065187893';
  };

  // Handler for email
  const handleEmail = () => {
    window.location.href = 'mailto:unitedsllc123@gmail.com';
  };

  return (
    <ThemeProvider theme={theme}>
      {/* Header Section */}
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            United Security LLC
          </Typography>
          <IconButton
            color="inherit"
            edge="start"
            sx={{ display: { xs: 'block', md: 'none' } }}
            onClick={(e) => setMenuAnchorEl(e.currentTarget)} // Open menu
          >
            <MenuIcon />
          </IconButton>
          <Hidden smDown>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button color="inherit" onClick={() => scrollToSection(aboutRef)}>About</Button>
              <Button color="inherit" onClick={() => scrollToSection(servicesRef)}>Services</Button>
              <Button color="inherit" onClick={() => scrollToSection(contactRef)}>Contact</Button>
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>

      {/* Menu for Mobile */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)} // Close menu
      >
        <MenuItem onClick={() => scrollToSection(aboutRef)}>About</MenuItem>
        <MenuItem onClick={() => scrollToSection(servicesRef)}>Services</MenuItem>
        <MenuItem onClick={() => scrollToSection(contactRef)}>Contact</MenuItem>
      </Menu>

      {/* Hero Section */}
      <Box
        className="hero-section"
        sx={{
          backgroundImage: `url(${myImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          color: 'white',
          textAlign: 'center',
          py: { xs: 4, md: 8 }, // Responsive padding
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h3" gutterBottom>
            Your Trusted Security Partner
          </Typography>
          <Typography variant="h6" paragraph>
            Providing top-notch security services for businesses and homes with cutting-edge technology and trained professionals.
          </Typography>
        </Container>
      </Box>

      {/* About Us Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 4, md: 8 } }} ref={aboutRef}>
        <Typography variant="h4" align="center" gutterBottom>
          About Us
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Box className="about-box">
              <Typography variant="body1" paragraph>
                Welcome to United Security LLC, a security guard company based in the heart of Seattle. We are dedicated to providing top-notch security solutions with a focus on professionalism, reliability, and community trust. Our team of trained, licensed, and experienced security professionals is committed to ensuring the safety and well-being of businesses, events, and residential areas throughout the Seattle area.
              </Typography>
              <Typography variant="body1" paragraph>
                At United Security LLC, we understand that every client has unique security needs. That's why we offer customized security services, ranging from on-site security and event monitoring to mobile patrols and emergency response. Our goal is to provide peace of mind through tailored solutions and exceptional customer service.
              </Typography>
              <Typography variant="body1" paragraph>
                As a local business, we take pride in supporting our community and fostering an inclusive environment. Trust United Security LLC to safeguard what matters most, with a team that puts your safety first.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Services Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 4, md: 8 } }} ref={servicesRef}>
        <Typography variant="h4" align="center" gutterBottom>
          Our Services
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box className="service-box" sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom>
                Surveillance Systems
              </Typography>
              <Typography paragraph>
              Effective utilization of high-definition surveillance systems for 24/7 monitoring and recording.
              </Typography>
              <Button variant="outlined" color="primary" onClick={() => scrollToSection(surveillanceRef)}>
                Read More
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="service-box" sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom>
                Access Control
              </Typography>
              <Typography paragraph>
                Secure access control solutions designed to protect your premises from unauthorized entry.
              </Typography>
              <Button variant="outlined" color="primary" onClick={() => scrollToSection(accessRef)}>
                Read More
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Surveillance Section */}
        <Box sx={{ mt: 8 }} ref={surveillanceRef}>
          <Typography variant="h5" gutterBottom>
            Surveillance Monitoring Services
          </Typography>
          <Typography variant="body1" paragraph>
            At United Security LLC, we understand that effective surveillance is key to maintaining a secure environment. Our trained security personnel specialize in monitoring your existing surveillance systems to ensure comprehensive oversight and rapid response to any potential threats.
          </Typography>
          <Typography variant="body1" paragraph>
            Our surveillance monitoring services include:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Expert Monitoring:</strong> Our licensed security guards are dedicated to actively monitoring your surveillance feeds, ensuring that any unusual activity is promptly identified and addressed. With a keen eye for detail, our team acts as your first line of defense against security breaches.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Real-Time Alerts:</strong> Leveraging advanced technology, our security personnel can provide real-time alerts and notifications for suspicious activities, allowing for swift action to be taken as needed. You can trust us to keep you informed and protected at all times.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Comprehensive Reporting:</strong> We believe in transparency and accountability. Our team provides regular reports on surveillance activities, offering valuable insights into your property's security status and any identified concerns.
          </Typography>
          <Typography variant="body1" paragraph>
            With United Security LLC, you can have confidence in the integrity and safety of your premises through our professional surveillance monitoring services.
          </Typography>
        </Box>

        {/* Access Control Section */}
        <Box sx={{ mt: 8 }} ref={accessRef}>
          <Typography variant="h5" gutterBottom>
            Access Control Solutions
          </Typography>
          <Typography variant="body1" paragraph>
            Our team at United Security LLC is committed to enhancing your security through effective management of your access control systems. Our security personnel are well-versed in managing and optimizing your existing access control systems to enhance the security of your premises.
          </Typography>
          <Typography variant="body1" paragraph>
            Our access control solutions include:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>System Familiarization:</strong> Our trained security guards will familiarize themselves with your current access control setup, ensuring that it functions optimally. By familiarizing themselves with the existing infrastructure, our security personnel can effectively manage permissions and monitor entry logs.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Customized Security Solutions:</strong> Understanding that every client has unique needs, we tailor our access control services to fit your specific requirements. Our guards will collaborate with you to create a comprehensive plan that enhances your existing access control measures.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Enhanced Security and Peace of Mind:</strong> By utilizing your existing access control system alongside our trained security personnel, you gain an added layer of protection for your property. Our team’s expertise ensures that your security protocols are enforced effectively, giving you peace of mind.
          </Typography>
          <Typography variant="body1" paragraph>
            With United Security LLC, you can trust that your access control measures are in capable hands, allowing you to focus on your business while we safeguard your assets.
          </Typography>
        </Box>
      </Container>

      {/* Contact Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 4, md: 8 } }} ref={contactRef}>
        <Typography variant="h4" align="center" gutterBottom>
          Contact Us
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box className="service-box" sx={{ textAlign: 'center', cursor: 'pointer', p: 2, border: '1px solid #ddd', borderRadius: 2 }} onClick={handleCall}>
              <PhoneIcon fontSize="large" />
              <Typography variant="h6">Phone</Typography>
              <Typography variant="body1">206-518-7893</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="service-box" sx={{ textAlign: 'center', cursor: 'pointer', p: 2, border: '1px solid #ddd', borderRadius: 2 }} onClick={handleEmail}>
              <EmailIcon fontSize="large" />
              <Typography variant="h6">Email</Typography>
              <Typography variant="body1">unitedsllc123@gmail.com</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Footer Section */}
      <Box sx={{ bgcolor: '#3f51b5', color: 'white', py: 4 }}>
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
          <Typography variant="body1" paragraph>
            &copy; {new Date().getFullYear()} United Security LLC. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
